.welcome-title {
  text-align: center;
  overflow: hidden;
  margin-top: 60px !important;
  @media only screen and (max-width: 600px) {
    margin: 30px 0 !important;
  }

  p {
    width: 80%;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  h1 {
    font-size: 23px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
}
